const styles = {
  dataBox: {
    flexGrow: 1,
    height: {
      xs: '30px',
      sm: '40px',
      md: '50px',
      lg: '60px'
    },
    background: '#114B76',
    padding: 0,
    borderRadius: '5px 5px 0px 0px',
    boxShadow: 'none'
  },

  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    minHeight: {
      xs: '26px',
      sm: '40px',
      md: '50px',
      lg: '64px'
    }
  },

  formHolder: {
    overflowY: 'auto',
    minHeight: 640,
    maxHeight: {
      xs: 895,
      lg: 1024
    }
  },

  boxContent: {
    display: 'flex',
    alignItems: 'center'
  },

  boxStat: {
    display: 'flex',
    marginRight: '2rem',
    alignItems: 'flex-start'
  },

  headerContent: {
    fontFamily: 'typography.fontFamily',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: {
      xs: '10px',
      sm: '10px',
      md: '12px',
      lg: '16px'
    },
    color: '#FFF',
    '& .MuiOutlinedInput-input': {
      color: '#FFF',
      fontSize: {
        xs: '12px',
        md: '14px',
        lg: '16px'
      },
      fontWeight: 600
    },

    '& .MuiSvgIcon-root': {
      opacity: 0
    }

  },

  statisticContent: {
    color: '#495057',
    fontSize: {
      xs: '10px',
      sm: '10px',
      md: '12px',
      lg: '14px',
      xl: '16px'
    }
  },
  textSelect: {
    textTransform: 'uppercase'
  },

  menuPanel: {
    background: '#B4CEE5',
    minHeight: '5.5rem',
    display: 'flex',
    padding: '0 1.5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#FFF'
  },

  dateContainer: {
    display: 'flex',
    maxWidth: '25rem',
    '& .MuiTextField-root:first-of-type, & .MuiTextField-root:last-of-type': {
      minWidth: 0,
      boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)'

    }

  },

  inputDate: {
    '&  MuiOutlinedInput-root': {
      maxHeight: '2.5rem',
      borderRadius: '4px'
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#FFF',
      borderRadius: '4px',
      color: '#495057',
      // border: '1px solid',
      outline: 'none',
      // minWidth: '100px',
      boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'none'
      }

    }

  },

  cardHeader: {
    background: '#B4CEE5',
    display: 'flex',
    height: '100%',
    '& .MuiCardHeader-title, & .MuiCardHeader-subheader': {
      fontSize: {
        md: '12px',
        lg: '14px'
      }
    },
    '& .MuiCardHeader-action': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      marginTop: 0,
      marginBottom: 0
    }
  },

  cardContentTypography: {
    color: '#495057',
    fontSize: {
      xs: '12px',
      lg: '12px',
      xl: '14px'
    },
    fontWeight: 400,
    marginBottom: '0.5rem',
    '&:last-of-type': {
      marginBottom: 0
    }
  }

};

export default styles;




