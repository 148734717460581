import React, { ChangeEvent, useState } from "react";
import { Box, Button } from "@mui/material";

import PropTypes from "prop-types";
import styles from "./EntityEditor.styles";
import GeneralFieldsBlock from "./entity-editor-blocks/GeneralFieldsBlock";
import EntityAddressBlock from "./entity-editor-blocks/EntityAddressBlock";
import EntityPhoneNumbersBlock from "./entity-editor-blocks/EntityPhoneNumbersBlock";
import EntityEmailsBlock from "./entity-editor-blocks/EntityEmailsBlock";
import EntitySocialBlock from "./entity-editor-blocks/EntitySocialBlock";
import EntityGDriveBlock from "./entity-editor-blocks/EntityGDriveBlock";
import { createNewEntityRequest } from "../../../modules/add-new-entity/actions";
import { useDispatch } from "react-redux";
import AlertService from "../../services/notification.service";
import { entityFormValidator, kadIdValidator } from "../helpers/validator";
import { entityTypes } from "../../constants/field-constants";
import { useNavigate } from 'react-router-dom';


const initialErrorsState = {
  isFilledLastName: true,
  isFilledCompanyName: true,
  // isFilledKadId: true,
};

const link =
  "https://kadaster-on-line.kadaster.nl/selectie.asp?identificatie=NL.IMKAD.Persoon.";
const kvkLink = "https://www.kvk.nl/orderstraat/product-kiezen/?kvknummer=";

const EntityEditor = (props) => {
  const initialEntityState = {
    general_fields: {
      entity_type: props.type,
      first_name: "",
      prefix: "",
      last_name: "",
      initials: "",
      all_names: "",
      nick_name: "",
      gender: "",
      blacklisted: "",
      is_resident: "",
      birth_date: "",
      birth_year: "",
      birth_place: "",
      is_dead: "",
      kadaster_personal_number: "",
      kadaster_objects_amount: "",
      kadaster_url: "",
      url: "",
      company_id: "",
      company_name: "",
      chamber_commerce_url: "",
      chamber_commerce_number: "",
    },
    addresses: [],
    phone_numbers: [],
    emails: [],
    socials: [],
    addressesForDelete: [],
    phoneNumbersForDelete: [],
    emailsForDelete: [],
    socialsForDelete: [],
  };

  const [entityForSave, setEntityForSave] = useState(initialEntityState);
  const [errors, setErrors] = useState(initialErrorsState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (
    name,
    key,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index
  ) => {
    if (name === "generalFields") {
      if (key === "kadaster_personal_number") {
        return setEntityForSave({
          ...entityForSave,
          general_fields: {
            ...entityForSave.general_fields,
            [key]: event.target.value,
            kadaster_url: link + event.target.value,
          },
        });
      }
      if (key === "chamber_commerce_number") {
        return setEntityForSave({
          ...entityForSave,
          general_fields: {
            ...entityForSave.general_fields,
            [key]: event.target.value,
            chamber_commerce_url: event.target.value
              ? kvkLink + event.target.value
              : "",
          },
        });
      }

      if (
        entityForSave.general_fields.entity_type === entityTypes.PERSON &&
        key === "last_name"
      ) {
        setErrors({ isFilledCompanyName: false, isFilledLastName: true });
      }
      if (
        entityForSave.general_fields.entity_type === entityTypes.COMPANY &&
        key === "company_name"
      ) {
        setErrors({ isFilledLastName: false, isFilledCompanyName: true });
      }

      setEntityForSave({
        ...entityForSave,
        general_fields: {
          ...entityForSave.general_fields,
          [key]: event.target.value,
        },
      });
    }
  };

  const updateEntity = (name, item, editMode) => {
    if (name === "entityAddresses") {
      if (editMode) {
        return setEntityForSave({
          ...entityForSave,
          // @ts-ignore
          addresses: [...item.filteredList],
          // @ts-ignore
          addressesForDelete: [...item.newStateForDelete],
        });
      }
      return setEntityForSave({
        ...entityForSave,
        // @ts-ignore
        addresses: [...item],
      });
    }
    if (name === "entityPhoneNumbers") {
      if (editMode) {
        return setEntityForSave({
          ...entityForSave,
          // @ts-ignore
          phone_numbers: [...item.filteredList],
          // @ts-ignore
          phoneNumbersForDelete: [...item.newStateForDelete],
        });
      }
      return setEntityForSave({
        ...entityForSave,
        // @ts-ignore
        phone_numbers: [...item],
      });
    }
    if (name === "entityEmails") {
      if (editMode) {
        return setEntityForSave({
          ...entityForSave,
          // @ts-ignore
          emails: [...item.filteredList],
          // @ts-ignore
          emailsForDelete: [...item.newStateForDelete],
        });
      }
      return setEntityForSave({
        ...entityForSave,
        // @ts-ignore
        emails: [...item],
      });
    }
    if (name === "entitySocials") {
      if (editMode) {
        return setEntityForSave({
          ...entityForSave,
          // @ts-ignore
          socials: [...item.filteredList],
          // @ts-ignore
          socialsForDelete: [...item.newStateForDelete],
        });
      }
      return setEntityForSave({
        ...entityForSave,
        // @ts-ignore
        socials: [...item],
      });
    }
  };

  const handleSave = async () => {
    let isExistedKadId;
    if (entityForSave.general_fields.kadaster_personal_number) {
      const type = "entity"; 
      isExistedKadId = await kadIdValidator(
        type,
        entityForSave.general_fields.kadaster_personal_number
      );
      console.log("isExistedKadId ", isExistedKadId);
      if (isExistedKadId) {
        return AlertService.warning("Entity with this KAD ID already exists");
      }
    }

    const isValidEntity = entityFormValidator(entityForSave);
    setErrors(isValidEntity);
    if (
      entityForSave.general_fields.entity_type === entityTypes.PERSON &&
      isValidEntity.isFilledLastName
    ) {
      console.log("New person");
      dispatch(createNewEntityRequest(entityForSave, navigate));
    } else if (
      entityForSave.general_fields.entity_type === entityTypes.COMPANY &&
      isValidEntity.isFilledCompanyName
    ) {
      console.log("New company");
      dispatch(createNewEntityRequest(entityForSave, navigate));
    } else {
      return AlertService.warning("fields are not valid");
    }
  };

  const handleCancel = () => {
    console.log("clear form!");
    setErrors(initialErrorsState);
    setEntityForSave({
      general_fields: {
        entity_type: props.type,
        first_name: "",
        prefix: "",
        last_name: "",
        initials: "",
        all_names: "",
        nick_name: "",
        gender: "",
        blacklisted: "",
        is_resident: "",
        birth_date: "",
        birth_year: "",
        birth_place: "",
        is_dead: "",
        kadaster_personal_number: "",
        kadaster_objects_amount: "",
        kadaster_url: "",
        url: "",
        company_id: "",
        company_name: "",
        chamber_commerce_url: "",
        chamber_commerce_number: "",
      },
      addresses: [],
      phone_numbers: [],
      emails: [],
      socials: [],
      addressesForDelete: [],
      phoneNumbersForDelete: [],
      emailsForDelete: [],
      socialsForDelete: [],
    });
  };

  return (
    <Box sx={styles.root}>
      <GeneralFieldsBlock
        entityType={props.type}
        onChange={handleChange}
        entityFields={entityForSave.general_fields}
        errors={errors}
      />
      <EntityAddressBlock
        entityAddresses={entityForSave.addresses}
        onChange={updateEntity}
      />
      <EntityPhoneNumbersBlock
        phoneNumbers={entityForSave.phone_numbers}
        onChange={updateEntity}
      />
      <EntityEmailsBlock
        emails={entityForSave.emails}
        onChange={updateEntity}
      />
      <EntitySocialBlock
        socials={entityForSave.socials}
        onChange={updateEntity}
      />
      <EntityGDriveBlock driveLink={""} onChange={updateEntity} />
      <Box sx={styles.buttonHolder}>
        <Button variant="contained" sx={styles.buttonSave} onClick={handleSave}>
          Save
        </Button>
        <Button
          variant="contained"
          sx={styles.buttonCansel}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

EntityEditor.propTypes = {
  type: PropTypes.string.isRequired,
};

export default EntityEditor;
