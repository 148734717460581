import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import {
  createNewEntitySuccess,
  createNewObjectSuccess,
  createNewEntityError,
  createNewObjectError,
} from "../actions";
import {
  CREATE_NEW_ENTITY_REQUEST,
  CREATE_NEW_OBJECT_REQUEST,
} from "../../../common/constants/action-constants";

function* createNewEntity(action) {
  try {
    const newEntity = yield call(ApiService.post, "/entity", action.entity);
    yield put(createNewEntitySuccess(newEntity.data));
    action.navigate(`/leadgen/entity/${newEntity.data.id}`);
  } catch (error) {
    yield put(createNewEntityError(error));
  }
}

function* createNewObject(action) {
  try {
    const newObject = yield call(ApiService.post, "/object", action.object);
    yield put(createNewObjectSuccess(newObject));
    action.navigate(`/leadgen/object/${newObject.data.id}`);
  } catch (error) {
    yield put(createNewObjectError(error));
  }
}

export default function* actionNewEntity() {
  yield takeLatest(CREATE_NEW_ENTITY_REQUEST, createNewEntity);
  yield takeLatest(CREATE_NEW_OBJECT_REQUEST, createNewObject);
}
