import { call, put, takeLatest, delay } from "redux-saga/effects";
import {
  CANCEL_PDF_REQUEST,
  CHECK_PDF_USAGE_REQUEST,
  SAVE_PDF_REQUEST,
  SEND_PDF_REQUEST,
} from "../../../common/constants/action-constants";
import {
  checkPDFUsageError,
  checkPDFUsageRequest,
  checkPDFUsageSuccess,
  savePDFError,
  savePDFSuccess,
  sendPDFError,
  sendPDFSuccess,
  unlinkPDFError,
  unlinkPDFSuccess,
} from "../actions";
import ApiService from "../../../common/services/api.service";
import AlertService from "../../../common/services/notification.service";

function* sendPDFData(action) {
  try {
    const pdfData = yield call(ApiService.post, "/upload/pdf", action.data);
    yield put(sendPDFSuccess(pdfData.data, action.navigate));
  } catch (error) {
    yield put(sendPDFError(error));
  }
}

function* savePDFData(action) {
  try {
    const pdfData = yield call(
      ApiService.post,
      "/upload/save_pdf_data",
      action.data
    );
    yield put(savePDFSuccess(pdfData));
    yield put(checkPDFUsageRequest(pdfData.data.PDFid, action.navigate));
  } catch (error) {
    yield put(savePDFError(error));
  }
}

function* unlinkFileFromServer(action) {
  try {
    const pdfData = yield call(ApiService.post, "/upload/unlink_pdf", {
      pdfData: action.pdfData,
      fileForDriveImport: action.fileForDriveImport,
    });
    yield put(unlinkPDFSuccess(pdfData));
  } catch (error) {
    yield put(unlinkPDFError(error));
  }
}

function* checkPDFUsage(action) {
  try {
    let result;
    while (!result) {
      result = yield call(ApiService.post, "/upload/checkpdf", {
        pdfId: action.pdfId,
      });
      if (result.data.canUse) {
        AlertService.success(result.data.message);
        if (result.data.entity_id)
          action.navigate(`/leadgen/entity/${result.data.entity_id}`);
        if (result.data.object_id)
          action.navigate(`/leadgen/object/${result.data.object_id}`)
        yield put(unlinkPDFSuccess({}));
        break;
      } else {
        if (result.data.timeout) {
          yield delay(result.data.timeout);
          yield put(checkPDFUsageRequest(action.pdfId, action.navigate));
        }
      }
      yield put(checkPDFUsageSuccess(result.data.message));
    }
  } catch (error) {
    yield put(checkPDFUsageError(error));
  }
}

export default function* actionPDF() {
  yield takeLatest(SEND_PDF_REQUEST, sendPDFData);
  yield takeLatest(SAVE_PDF_REQUEST, savePDFData);
  yield takeLatest(CANCEL_PDF_REQUEST, unlinkFileFromServer);
  yield takeLatest(CHECK_PDF_USAGE_REQUEST, checkPDFUsage);
}
