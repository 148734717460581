import {
  RECEIVE_ENTITY_DETAILS_REQUEST,
  RECEIVE_ENTITY_DETAILS_SUCCESS,
  RECEIVE_ENTITY_DETAILS_ERROR,
  UPDATE_ENTITY_REQUEST,
  UPDATE_ENTITY_SUCCESS,
  UPDATE_ENTITY_ERROR,
  RECEIVE_DEPENDENT_ENTITIES_REQUEST,
  RECEIVE_DEPENDENT_ENTITIES_SUCCESS,
  RECEIVE_DEPENDENT_ENTITIES_ERROR,
} from '../../../common/constants/action-constants';
import AlertService from "../../../common/services/notification.service";

export const receiveMainEntityDetailsRequest = (entityId) => {
  return {
    type: RECEIVE_ENTITY_DETAILS_REQUEST,
    entityId,
  };
};

export const receiveMainEntityDetailsSuccess = (entity) => {
  return {
    type: RECEIVE_ENTITY_DETAILS_SUCCESS,
    entity,
  };
};

export const receiveMainEntityDetailsError = (error) => {
  return {
    type: RECEIVE_ENTITY_DETAILS_ERROR,
    error,
  };
};

export const updateEntityRequest = (
  deleteMode,
  actionTypes,
  entityId,
  data
) => {
  return {
    type: UPDATE_ENTITY_REQUEST,
    deleteMode,
    actionTypes,
    entityId,
    data,
  };
};

export const updateEntitySuccess = (entity, message) => {
  AlertService.success(message);
  return {
    type: UPDATE_ENTITY_SUCCESS,
    entity,
  };
};

export const updateEntityError = (error) => {
  return {
    type: UPDATE_ENTITY_ERROR,
    error,
  };
};

export const receiveDependentEntitiesRequest = (entityId) => {
  return {
    type: RECEIVE_DEPENDENT_ENTITIES_REQUEST,
    id: entityId
  }
}

export const receiveDependentEntitiesSuccess = (dependentEntities) => {
  return {
    type: RECEIVE_DEPENDENT_ENTITIES_SUCCESS,
    dependentEntities
  }
}

export const receiveDependentEntitiesError = (error) => {
  return {
    type: RECEIVE_DEPENDENT_ENTITIES_ERROR,
    error,
  };
}
