import React from "react";
import { Box, Typography } from "@mui/material";
import styles from "./AccessDenied.styles";

const AccessDeniedPage = () => {
  return (
    <Box sx={styles.container}>
      <Typography variant="h4" sx={styles.typography}>
        Sorry, you don't have a rights for this page. Contact system admin or super admin to solve this.
      </Typography>
      <Box sx={styles.imgBg}>
        <img src="images/error-page.png" alt="error" />
      </Box>
    </Box>
  );
};

export default AccessDeniedPage;
