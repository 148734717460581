const styles = {
  item: {
    width: "100%",
    display: "flex",
    marginTop: 18,
    flexDirection: "column",
  },
  warningText: {
    display: "flex",
    justifyContent: "center",
    marginTop: 24,
    color: "red",
  },
  body: {
    display: "flex",
    justifyContent: "space-between",
    padding: 2,
    flexDirection: "column",
    // height: "calc(100% - 24px)",
  },
  confirmText: {
    marginTop: 20,
  },
  errorText: {
    // color: theme.palette.secondary.error,
    textAlign: "center",
  },
  content: {
    // height: `calc(100vh - ${200}px)`,
    height: "89vh",
  },
  dragWrapper: {
    display: "flex",
    height: "inherit",
    outline: "none",
    cursor: "pointer",
  },
  dragWrapperIsActive: {
    backgroundColor: "#f5f5f5",
  },
  wrapperText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "16px",
    border: "2px dashed #999",
    borderRadius: 4,
  },
  wrapperSpinner: {
    display: "flex",
    height: "inherit",
    justifyContent: "center",
    alignItems: "center",
  },

  textColumn: {
    // lineHeight: "2.65em"
  },
  entityObjectInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "column",
  },


  table: {
    height: "inherit",
    // maxHeight: {
    //   xs: 895,
    //   lg: 1024,
    // },
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
  dataHeaderTable: {
    fontFamily: "typography.fontFamily",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: {
      xs: "12px",
      lg: "14px",
    },
    padding: {
      xs: "0 10px 0",
      sm: "7px 6px",
      md: "10px 9px",
      lg: "16px",
    },
    color: "#495057",
    backgroundColor: "secondary.light",
  },
  dataTableBody: {
    width: "300px",
    fontFamily: "typography.fontFamily",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: {
      xs: "10px",
      sm: "12px",
      lg: "14px",
    },
    padding: {
      xs: "5px",
      sm: "5px",
      md: "8px",
      lg: "14px",
      xl: "16px",
    },
    color: "#495057",
  },
  buttonTableBody: {
    fontFamily: "typography.fontFamily",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: {
      xs: "10px",
      sm: "12px",
      lg: "14px",
    },
    color: "#495057",
    background: "rgba(180, 206, 229, 0.44)",
    borderRadius: "5px",
  },
};

export default styles;