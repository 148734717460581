import { Grid, Paper, Box, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { selectLeadgen } from "../../selectors";
import Object from "./Object";
import Maps from "../common/Maps";
import styles from "../leadgen-entity/EntitiesBlock.styles";

interface ObjectBlockProps {
  objectId?: string | undefined;
}

const mapStyles = {
  height: "85vh",
  marginTop: "1.5em",
};

const ObjectBlock = (props: ObjectBlockProps) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container columns={2} spacing={3}>
        <Grid item xs={1}>
          <Paper>
            <Object objectId={props.objectId} />
          </Paper>
        </Grid>
        <Grid item xs={1}>
          <Paper sx={{ padding: "5px 15px" }}>
            <Typography
              variant="h4"
              component="div"
              sx={styles.contentTitleMain}
            >
              Maps
            </Typography>
            <Maps mapStyles={mapStyles} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

function mapStateToProps(state) {
  const { entityObjectsList, objectsAmount } = selectLeadgen(state);
  return { entityObjectsList, objectsAmount };
}

export default connect(mapStateToProps)(ObjectBlock);
