import {
  SEARCH_GET_ENTITY_OBJECT_REQUEST,
  SEARCH_GET_ENTITY_OBJECT_SUCCESS,
  SEARCH_GET_ENTITY_OBJECT_ERROR,
  SEARCH_RECEIVE_CITY_REQUEST,
  SEARCH_RECEIVE_CITY_SUCCESS,
  SEARCH_RECEIVE_CITY_ERROR,
  SEARCH_GET_ENTITY_REQUEST,
  SEARCH_GET_ENTITY_SUCCESS,
  SEARCH_GET_ENTITY_ERROR,
  SEARCH_GET_OBJECT_REQUEST, SEARCH_GET_OBJECT_SUCCESS, SEARCH_GET_OBJECT_ERROR
} from '../../../common/constants/action-constants';

export const findEntityAndObjectByFiltersRequest = (requestData) => {
  return {
    type: SEARCH_GET_ENTITY_OBJECT_REQUEST,
    requestData
  }
}

export const findEntityAndObjectByFiltersSuccess = (result) => {
  return {
    type: SEARCH_GET_ENTITY_OBJECT_SUCCESS,
    result
  }
}

export const findEntityAndObjectByFiltersError = (error) => {
  return {
    type: SEARCH_GET_ENTITY_OBJECT_ERROR,
    error
  }
}

export const receiveCityRequest = (requestData) => {
  return {
    type: SEARCH_RECEIVE_CITY_REQUEST,
    requestData
  }
}

export const receiveCitySuccess = (result) => {
  return {
    type: SEARCH_RECEIVE_CITY_SUCCESS,
    result
  }
}

export const receiveCityError = (error) => {
  return {
    type: SEARCH_RECEIVE_CITY_ERROR,
    error,
  };
};

export const findEntityByFiltersRequest = (requestData) => {
  return {
    type: SEARCH_GET_ENTITY_REQUEST,
    requestData
  }
}

export const findEntityByFilterSuccess = (result) => {
  return {
    type: SEARCH_GET_ENTITY_SUCCESS,
    result
  }
}

export const findEntityByFiltersError = (error) => {
  return {
    type: SEARCH_GET_ENTITY_ERROR,
    error
  }
}


export const findObjectByFiltersRequest = (requestData) => {
  return {
    type: SEARCH_GET_OBJECT_REQUEST,
    requestData
  }
}

export const findObjectByFilterSuccess = (result) => {
  return {
    type: SEARCH_GET_OBJECT_SUCCESS,
    result
  }
}

export const findObjectByFiltersError = (error) => {
  return {
    type: SEARCH_GET_OBJECT_ERROR,
    error
  }
}
