import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Box, Button } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "./EntitiesBlock.styles";
import StyledTextField from "../../../../common/components/styled-components/StyledTextField";
import { receiveEntityNotesRequest, removeNoteRequest, createUpdateNoteRequest } from '../../actions/entityNotes';
import { selectLeadgen } from "../../selectors";
import { formatDateAndTime } from '../../../../common/formats/date-fromatter';

interface EntityNotesProps {
  entityId: string | undefined;
  entityNotes?: {
    entityNote: {
      id?: any,
      body: any,
      title: string,
      entity_id: string,
      user_id: number,
      updated_at: any,
      noteAuthor: {
        id?: number | null,
        name?: string,
        surname?: string,
      }
    },
    error?: any
  };
}

let editor;
const editorPlaceholder = document.querySelector("#editor") as HTMLElement;
ClassicEditor.create(editorPlaceholder).then(newEditor => {
   editor = newEditor;
} ).catch((error) => {
  console.error(error);
});

const EntityNotes = (props: EntityNotesProps) => {
  const dispatch = useDispatch();
  const { entityId, entityNotes } = props;
  const [note, setNote] = useState("");
  const [title, setTitle] = useState("");
  const [noteId, setNoteId] = useState(null);
  const [disableNoteEdit, setDisableNoteEdit] = useState(true);
  const [noteAuthor, setNoteAuthor] = useState({id: null, name: 'User', surname: ''});
  const noteAuthorFullName = noteAuthor.name && noteAuthor.surname ? `${noteAuthor.name} ${noteAuthor.surname}` :
    noteAuthor.name ? `${noteAuthor.name}` : `${noteAuthor.surname}`;
  const lastUpdateDate = (entityNotes && entityNotes.entityNote && formatDateAndTime(entityNotes.entityNote.updated_at)) || "";

  useEffect(() => {
    if (editor) {
      editor.setData(note);
    }
  }, [note]);

  useEffect(() => {
    dispatch(receiveEntityNotesRequest(entityId));
  }, [dispatch, entityId]);

  useEffect(() => {
    entityNotes && entityNotes.entityNote && entityNotes.entityNote.body && setNote(entityNotes.entityNote.body);

  }, [dispatch, entityNotes]);

  useEffect(() => {
    entityNotes && entityNotes.entityNote && entityNotes.entityNote.title && setTitle(entityNotes.entityNote.title);
  }, [dispatch, entityNotes]);

  useEffect(() => {
    // @ts-ignore
    entityNotes && entityNotes.entityNote && entityNotes.entityNote.title && setNoteAuthor(entityNotes.entityNote.noteAuthor);
  }, [dispatch, entityNotes]);

  useEffect(() => {
    entityNotes && entityNotes.entityNote && entityNotes.entityNote.id && setNoteId(entityNotes.entityNote.id);
  }, [dispatch, entityNotes]);

  const handleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleChangeNote = (data) => {
    setNote(data);
  };

  const handleEditNote = () => {
    setDisableNoteEdit(false);
  };

  const handleSaveNote = () => {
    setDisableNoteEdit(true);
    dispatch(createUpdateNoteRequest({
      entity_id: entityId,
      body: note,
      title,
      id: noteId
    }))
  };
  const handleDeleteNote = () => {
    dispatch(removeNoteRequest(noteId))
    setNote("");
    setTitle("");
    setNoteId(null);
  };

  const handleCancel = () => {
    setDisableNoteEdit(true);
    setNote(entityNotes && entityNotes.entityNote ? entityNotes.entityNote.body : "");
    setTitle(entityNotes && entityNotes.entityNote ? entityNotes.entityNote.title : "");
  };

  return (
    <Box>
      {noteId ? <Box sx={styles.userInfo}>Last modified by {noteAuthorFullName}, {lastUpdateDate}</Box> :
        <Box sx={styles.userInfo}>No saved notes at this time</Box>
      }
      <Box sx={{ marginBottom: "10px 0 5px 0" }}>
        <StyledTextField
          size="small"
          fullWidth
          required
          label="Title"
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={title}
          disabled={disableNoteEdit}
        />
      </Box>
      <Box sx={styles.editorArea}  id="editor">
        <CKEditor
          // @ts-ignore
          editor={ClassicEditor}
          config={{
            toolbar: [
              "heading",
              "|",
              "bold",
              "italic",
              "link",
              "bulletedList",
              "numberedList",
              "blockQuote",
            ],
            heading: {
              options: [
                {
                  model: "paragraph",
                  title: "Paragraph",
                  class: "ck-heading_paragraph",
                },
                {
                  model: "heading1",
                  view: "h1",
                  title: "Heading 1",
                  class: "ck-heading_heading1",
                },
                {
                  model: "heading2",
                  view: "h2",
                  title: "Heading 2",
                  class: "ck-heading_heading2",
                },
              ],
            },
          }}
          data={note}
          disabled={disableNoteEdit}
          onReady={(editor) => {}}
          // @ts-ignore
          onChange={(e, editor) => handleChangeNote(editor.getData())}
        />
      </Box>
      {disableNoteEdit ? (
        <Box sx={styles.buttonHolder}>
          <Button
            variant="contained"
            sx={styles.buttonEdit}
            onClick={handleEditNote}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            sx={styles.buttonDelete}
            onClick={handleDeleteNote}
          >
            Delete
          </Button>
        </Box>
      ) : (
        <Box sx={styles.buttonHolder}>
          <Button
            variant="contained"
            sx={styles.buttonEdit}
            onClick={handleSaveNote}
          >
            Save
          </Button>
          <Button
            variant="contained"
            sx={styles.buttonCansel}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  );
};

function mapStateToProps(state) {
  const entityNotes = selectLeadgen(state);
  return entityNotes;
}

export default connect(mapStateToProps)(EntityNotes);
