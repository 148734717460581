import { call, put, takeLatest } from "redux-saga/effects";
import { GET_OBJECT_BY_ID_REQUEST, GET_OBJECTS_BY_OWNER_REQUEST } from '../../../common/constants/action-constants';
import ApiService from "../../../common/services/api.service";
import { receiveObjectByIdSuccess, receiveObjectsByOwnerError, receiveObjectsByOwnerSuccess } from '../actions/objects';
import { receiveObjectsStakeholdersRequest } from '../actions/objectsStakeholders';

const LIMIT = 100;

function* getObjectByOwnerList(action) {
  try {
    const objects = yield call(ApiService.post, "/object/by_owner", {
      id: action.id,
      index: 0,
      limit: LIMIT,
    });
    if(objects) {
      yield put(receiveObjectsByOwnerSuccess(objects.data));
      const objectsIds = objects.data.map((object:any) => object.object_id)
      yield put(receiveObjectsStakeholdersRequest(objectsIds, null)); //temp add entity id like null because now we don't need it
    }
  } catch (e) {
    yield put(receiveObjectsByOwnerError(e))
  }
}

function* getObjectById(action) {
  try {
    const object = yield call(ApiService.get, `/object/data/${action.id}`);
    if(object) {
      yield put(receiveObjectByIdSuccess(object.data));
      // const objectsIds = objects.data.map((object:any) => object.object_id)
      // yield put(receiveObjectsStakeholdersRequest(objectsIds, null)); //temp add entity id like null because now we don't need it
    }
  } catch (e) {
    yield put(receiveObjectsByOwnerError(e))
  }
}

export default function* actionObjects() {
  yield takeLatest(GET_OBJECTS_BY_OWNER_REQUEST, getObjectByOwnerList);
  yield takeLatest(GET_OBJECT_BY_ID_REQUEST, getObjectById);
}
