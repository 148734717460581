import { createSelector } from "reselect";
import searchResult from '../reducers/search';
//
// const searchState = (state) => {
//   if (state && state.search) {
//     return state.search;
//   }
//   return null;
// };
//
// const searchSelector = createSelector(
//   [searchState],
//   (state) => state //return search
// );
//
// /*city*/
// const searchCitySelector = createSelector(
//   [searchSelector],
//   (state) => state.city
// );
//
// const searchCityReceiveSelector = createSelector(
//   [searchCitySelector],
//   (state) => state.receiveCity
// );
//
// const searchCityQuerySelector = createSelector(
//   [searchCitySelector],
//   (state) => state.setQueryCity
// );
// /*city*/
//
// /*company*/
// const searchCompanySelector = createSelector(
//   [searchSelector],
//   (state) => state.company
// );
//
// const searchCompanyReceiveSelector = createSelector(
//   [searchCompanySelector],
//   (state) => state.receiveCompany
// );
//
// const searchCurrentCompanySelector = createSelector(
//   [searchCompanySelector],
//   (state) => state.setCompany
// );
// /*company*/
//
// /*lps*/
// const searchLpsSelector = createSelector(
//   [searchSelector],
//   (state) => state.lps
// );
//
// const searchLpsDataSelector = createSelector([searchLpsSelector], (state) => {
//   return state.lpsConfigs;
// });
//
// const searchLpsUpdatedConfigSelector = createSelector(
//   [searchLpsSelector],
//   (state) => state.updatedConfig
// );
//
// const searchLpsFetchSelector = createSelector(
//   [searchLpsSelector],
//   (state) => state.isFetching
// );
//
// const searchLpsResultSelector = createSelector(
//   [searchLpsSelector],
//   (state) => state.result
// );
//
// const searchLpsIndexLPSItemSelector = createSelector(
//   [searchLpsSelector],
//   (state) => state.indexLPSItem
// );
//
// const searchLpsSelectedUserIdSelector = createSelector(
//   [searchLpsSelector],
//   (state) => state.selectedUserId
// );
// /*lps*/
//
// /*name*/
// const searchNameSelector = createSelector(
//   [searchSelector],
//   (state) => state.name
// );
//
// const searchReceiveNameSelector = createSelector(
//   [searchNameSelector],
//   (state) => state.receiveResByName
// );
//
// const searchRequestNameSelector = createSelector(
//   [searchNameSelector],
//   (state) => state.requestName
// );
// /*name*/
//
// /*object*/
// const searchObjectSelector = createSelector(
//   [searchSelector],
//   (state) => state.object
// );
//
// const searchReceiveObjectSelector = createSelector(
//   [searchObjectSelector],
//   (state) => state.receiveObject
// );
// /*object*/
//
// /* person */
// const searchPersonSelector = createSelector(
//   [searchSelector],
//   (state) => state.person
// );
//
// const searchReceivePersonSelector = createSelector(
//   [searchPersonSelector],
//   (state) => state.receivePerson
// );
// /* person */
//
// /*street*/
// const searchStreetSelector = createSelector(
//   [searchSelector],
//   (state) => state.street
// );
//
// const searchStreetReceiveSelector = createSelector(
//   [searchStreetSelector],
//   (state) => state.receiveByStreet
// );
//
// const searchStreetRequestSelector = createSelector(
//   [searchStreetSelector],
//   (state) => state.requestByStreet
// );
//
// const searchStreetListSelector = createSelector(
//   [searchStreetSelector],
//   (state) => state.receiveStreetList
// );
// /*street*/
//
// /*phone*/
// const searchPhoneSelector = createSelector(
//   [searchSelector],
//   (state) => state.phone
// );
//
// const searchPhoneReceiveSelector = createSelector(
//   [searchPhoneSelector],
//   (state) => state.receiveResByPhone
// );
// const searchPhoneRequestSelector = createSelector(
//   [searchPhoneSelector],
//   (state) => state.requestPhone
// );
// /*phone*/
//
// /*settings*/
// const searchSettingsSelector = createSelector(
//   [searchSelector],
//   (state) => state.settings
// );
//
// const searchReceiveSettingsSelector = createSelector(
//   [searchSettingsSelector],
//   (state) => state.receiveSettings
// );
// /*settings*/
//
// export const selectSearch = createSelector(
//   [
//     searchSelector,
//     searchCitySelector,
//     searchCityReceiveSelector,
//     searchCityQuerySelector,
//     searchCompanySelector,
//     searchCompanyReceiveSelector,
//     searchCurrentCompanySelector,
//     searchLpsSelector,
//     searchLpsFetchSelector,
//     searchLpsDataSelector,
//     searchLpsUpdatedConfigSelector,
//     searchLpsResultSelector,
//     searchLpsIndexLPSItemSelector,
//     searchLpsSelectedUserIdSelector,
//     searchNameSelector,
//     searchReceiveNameSelector,
//     searchRequestNameSelector,
//     searchObjectSelector,
//     searchReceiveObjectSelector,
//     searchPersonSelector,
//     searchReceivePersonSelector,
//     searchStreetSelector,
//     searchStreetReceiveSelector,
//     searchStreetRequestSelector,
//     searchStreetListSelector,
//     searchPhoneReceiveSelector,
//     searchPhoneRequestSelector,
//     searchSettingsSelector,
//     searchReceiveSettingsSelector,
//   ],
//   (
//     search,
//     city,
//     receiveCity,
//     setQueryCity,
//     company,
//     receiveCompany,
//     setCompany,
//     lps,
//     isFetching,
//     lpsConfigs,
//     updatedConfig,
//     result,
//     indexLPSItem,
//     selectedUserId,
//     name,
//     receiveResByName,
//     requestName,
//     object,
//     receiveObject,
//     person,
//     receivePerson,
//     street,
//     receiveByStreet,
//     requestByStreet,
//     receiveStreetList,
//     receiveResByPhone,
//     requestPhone,
//     settings,
//     receiveSettings
//   ) => {
//     const lpsOptions = lpsConfigs.map((i) => ({
//       value: i.name,
//       label: i.name,
//     }));
//     const currentOptions = lpsConfigs.map((i) => ({
//       value: i.id,
//       label: i.name,
//     }));
//
//     const lpcData = result ? result || {} : {};
//
//     const lastRecalculationDate =
//       lpcData && lpcData.last_recalculation_date
//         ? lpcData.last_recalculation_date
//         : "";
//     const countOfLeads = lpcData && lpcData.lpc ? lpcData.lpc.length : -1;
//     const indexOfCurrentLeadID = indexLPSItem;
//     // console.log('countOfLeads ', countOfLeads);
//     // console.log('indexOfCurrentLeadID ', indexOfCurrentLeadID);
//     const lpsConfig = lpsConfigs.map((value) => {
//       return {
//         ...value,
//         lpsc_object_condition_rules: value.lpsc_object_condition_rules,
//         lps_config_entity_KOA_rules: value.lps_config_entity_KOA_rules,
//         // lps_config_entity_rules: value.lps_config_entity_rules,
//         // lpsc_object_label_rules: { labelRules: value.lpsc_object_label_rules },
//         // lpsc_object_condition_rules: value.lpsc_object_condition_rules.reduce(
//         //   (acc, cur) => {
//         //     const { key, created_at, updated_at, ...rest } = cur;
//         //     return { ...acc, [cur.key]: rest };
//         //   },
//         //   {}
//         // ),
//       };
//     });
//     const objectsByStreet = receiveByStreet ? receiveByStreet : [];
//     const isFetchingObjects = requestByStreet ? requestByStreet : false;
//     const entitiesByName = receiveResByName ? receiveResByName : [];
//     const entitiesByPhone = receiveResByPhone ? receiveResByPhone : [];
//
//     const entitiesList = [];
//     entitiesByName.forEach((entity) => {
//       entitiesList.push(entity);
//     });
//     entitiesByPhone.forEach((entity) => {
//       entitiesList.push(entity);
//     });
//     const fullEntitiesList = entitiesList.filter((entity) => entity !== null);
//
//     const companyData = receiveCompany ? receiveCompany : [];
//     const selectedCompanyName = setCompany.company_name;
//     const isFetchingEntities = requestName ? requestName : false;
//     const getSettings = receiveSettings ? receiveSettings : [];
//     const processing = isFetching ? isFetching : false;
//     const queryCity = setQueryCity ? setQueryCity : "";
//     const objectData = receiveObject ? receiveObject : [];
//     const personData = receivePerson ? receivePerson : [];
//     const assign = lps.assigned;
//     const isCalculate = lps.isCalculated;
//
//     return {
//       updatedConfig,
//       lpsConfigs,
//       lpsOptions,
//       currentOptions,
//       lpcData,
//       lastRecalculationDate,
//       countOfLeads,
//       indexOfCurrentLeadID,
//       lpsConfig,
//       selectedUserId,
//       objectsByStreet,
//       receiveStreetList,
//       isFetchingObjects,
//       entitiesByName,
//       entitiesByPhone,
//       fullEntitiesList,
//       isFetchingEntities,
//       getSettings,
//       processing,
//       queryCity,
//       city,
//       receiveCity,
//       objectData,
//       personData,
//       companyData,
//       setCompany,
//       selectedCompanyName,
//       assign,
//       isCalculate,
//     };
//   }
// );


const searchState = (state) => {
  if (state && state.search) {
    return state.search;
  }
  return null;
};

const searchSelector = createSelector(
  [searchState],
  (state) => state //return search
);

/** settings **/
const searchSettingsSelector = createSelector(
  [searchSelector],
  (state) => state.settings
);

const searchReceiveSettingsSelector = createSelector(
  [searchSettingsSelector],
  (state) => state.receiveSettings
);
/** settings **/

/** global search results **/

const searchReceiveGlobalResultsSelector = createSelector(
  [searchSelector],
  (state) => state.searchResult
);

// const searchReceiveGlobalResultsCitySelector = createSelector(
//   [searchReceiveGlobalResultsSelector],
//   (state) => state.receiveSearchCitiesResult
// );

export const selectSearch = createSelector(
  [
    searchSelector,
    searchSettingsSelector,
    searchReceiveSettingsSelector,
    searchReceiveGlobalResultsSelector,
    // searchReceiveGlobalResultsCitySelector
  ],
  (search, settings, receiveSettings, searchResult) => {
    const getSettings = receiveSettings ? receiveSettings : {};
    const globalSearchResult = searchResult && searchResult.receiveSearchResult ? searchResult.receiveSearchResult : {};
    const localSearchEntityResult = searchResult && searchResult.entitiesResult && searchResult.entitiesResult.entities ? searchResult.entitiesResult.entities : [];
    const localSearchObjectResult = searchResult && searchResult.objectsResult && searchResult.objectsResult.objects ? searchResult.objectsResult.objects : [];
    const globalSearchResultEntities = searchResult && searchResult.receiveSearchResult && searchResult.receiveSearchResult.entities ? searchResult.receiveSearchResult.entities : [];
    const globalSearchResultObjects = searchResult && searchResult.receiveSearchResult && searchResult.receiveSearchResult.objects ? searchResult.receiveSearchResult.objects : [];
    const citiesResult = searchResult && searchResult.receiveSearchCitiesResult ? searchResult.receiveSearchCitiesResult : [];
    const processing = searchResult && searchResult.receiveSearchProcessing ? searchResult.receiveSearchProcessing.isFetching : false;

    return (
    {
      search,
      getSettings,
      settings,
      globalSearchResult,
      globalSearchResultEntities,
      globalSearchResultObjects,
      citiesResult,
      localSearchEntityResult,
      localSearchObjectResult,
      processing,
    }
  );
})




