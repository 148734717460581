import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import { RECEIVE_OBJECTS_OWNERS_REQUEST } from "../../../common/constants/action-constants";
import {
  receiveObjectsStakeholdersError,
  receiveObjectsStakeholdersSuccess,
} from "../actions/objectsStakeholders";

function* getObjectOwners(action) {
  try {
    const owners = yield call(ApiService.post, "/entity/owners", { ids: action.ids });
    if (owners) {
      yield put(receiveObjectsStakeholdersSuccess(owners.data));
    }
  } catch (e) {
    yield put(receiveObjectsStakeholdersError(e));
  }
}

export default function* actionStakeholders() {
  yield takeLatest(RECEIVE_OBJECTS_OWNERS_REQUEST, getObjectOwners);
}
