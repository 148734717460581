import { call, put, takeLatest } from "redux-saga/effects";
import {
  RECEIVE_DEPENDENT_ENTITIES_REQUEST,
  RECEIVE_ENTITY_DETAILS_REQUEST,
  UPDATE_ENTITY_REQUEST
} from '../../../common/constants/action-constants';
import ApiService from "../../../common/services/api.service";
import {
  receiveDependentEntitiesError,
  receiveDependentEntitiesSuccess,
  receiveMainEntityDetailsError,
  receiveMainEntityDetailsSuccess,
  updateEntityError,
  updateEntitySuccess
} from '../actions/entityDetails';
import { receiveObjectsByOwnerRequest } from '../actions/objects';
import { log } from 'mui-tel-input/dist/shared/helpers/log';

function* getEntityDetails(action) {
  try {
    const entity = yield call(ApiService.get, `/entity/${action.entityId}`);
    if (entity) {
      yield put(receiveMainEntityDetailsSuccess(entity.data));
    }
  } catch (e) {
    yield put(
      receiveMainEntityDetailsSuccess(receiveMainEntityDetailsError(e))
    );
  }
}

function* updateEntity(action) {
  console.log('action entityId ', action.entityId);
  try {
    console.log('action entity',action);
    const updatedEntity = yield call(
      ApiService.put,
      `/entity/${action.entityId}`,
      {
        deleteMode: action.deleteMode,
        types: action.actionTypes,
        info: action.data,
      }
    );
    console.log('updatedEntity', updatedEntity);
    if (updatedEntity) {
      yield put(
        updateEntitySuccess(
          updatedEntity.data,
          "Entity was successfully updated!"
        )
      );
      if(action.actionTypes.includes("objects")) {
        yield put(receiveObjectsByOwnerRequest(action.entityId))
      }
    }
  } catch (e) {
    yield put(updateEntityError(e));
  }
}

function* getDependentEntities(action) {
  try {
    const dependentEntities = yield call(ApiService.get, `/entity/${action.id}/dependent_entities`);
    if(dependentEntities) {
      yield put(receiveDependentEntitiesSuccess(dependentEntities.data))
    }
  } catch (e) {
    yield put(receiveDependentEntitiesError(e))
  }
}


export default function* actionEntity() {
  yield takeLatest(RECEIVE_ENTITY_DETAILS_REQUEST, getEntityDetails);
  yield takeLatest(UPDATE_ENTITY_REQUEST, updateEntity);
  yield takeLatest(RECEIVE_DEPENDENT_ENTITIES_REQUEST, getDependentEntities);
}
