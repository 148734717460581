import { combineReducers } from "redux";
import {
  RECEIVE_DEPENDENT_ENTITIES_ERROR,
  RECEIVE_DEPENDENT_ENTITIES_SUCCESS,
  RECEIVE_ENTITY_DETAILS_ERROR,
  RECEIVE_ENTITY_DETAILS_SUCCESS,
  UPDATE_ENTITY_SUCCESS
} from '../../../common/constants/action-constants';

const receiveEntity = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_ENTITY_DETAILS_SUCCESS:
    case UPDATE_ENTITY_SUCCESS:
      return action.entity;
    default:
      return state;
  }
};

const entityError = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_ENTITY_DETAILS_ERROR:
    case RECEIVE_DEPENDENT_ENTITIES_ERROR:
      return action.error;
    default:
      return state;
  }
};

const dependentEntities = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_DEPENDENT_ENTITIES_SUCCESS:
      return action.dependentEntities;
    default:
      return state;
  }
}

const currentEntity = combineReducers({
  entity: receiveEntity,
  dependentEntities,
  error: entityError,
});

export default currentEntity;
