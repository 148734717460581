import { combineReducers } from "redux";
import {
  GET_OBJECT_BY_ID_ERROR,
  GET_OBJECT_BY_ID_SUCCESS,
  GET_OBJECTS_BY_OWNER_ERROR,
  GET_OBJECTS_BY_OWNER_SUCCESS, SHOW_OBJECT_ADDRESS_ON_MAP
} from '../../../common/constants/action-constants';

const receiveObjects = (state = [], action) => {
  switch (action.type) {
    case GET_OBJECTS_BY_OWNER_SUCCESS:
      return action.objects;
    default:
      return state;
  }
};


const currentObject = (state = {}, action) => {
  switch (action.type) {
    case GET_OBJECT_BY_ID_SUCCESS:
      return action.object;
    default:
      return state;
  }
};

const objectsError = (state = {}, action) => {
  switch (action.type) {
    case GET_OBJECTS_BY_OWNER_ERROR:
    case GET_OBJECT_BY_ID_ERROR:
      return action.error;
    default:
      return state;
  }
};

const objectAddressForMap = (state = {}, action) => {
  switch (action.type) {
    case SHOW_OBJECT_ADDRESS_ON_MAP:
      return action.address;
    default:
      return state;
  }
}

const objects = combineReducers({
  receiveObjects,
  currentObject,
  address: objectAddressForMap,
  error: objectsError,
});

export default objects;
