import { combineReducers } from "redux";
import {
  RECEIVE_OBJECTS_OWNERS_ERROR,
  RECEIVE_OBJECTS_OWNERS_SUCCESS
} from '../../../common/constants/action-constants';

const receiveStakeholders = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_OBJECTS_OWNERS_SUCCESS:
      return action.owners;
    default:
      return state;
  }
};

const stakeholdersError = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_OBJECTS_OWNERS_ERROR:
    default:
      return state;
  }
}

export const objectsOwners = combineReducers({
  owners: receiveStakeholders,
  error: stakeholdersError,
})