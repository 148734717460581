import { Box, Typography } from '@mui/material';
import styles from '../leadgen-entity/EntitiesBlock.styles';

const Item = ({ title, text }) => {
  return (
    <Box sx={styles.infoRow}>
        <Typography sx={styles.contentTitle} variant="body2">
          {title}
        </Typography>
      <div>
        <Typography sx={styles.contentSubTitle} variant="body2">
          {text}
        </Typography>
      </div>
    </Box>
  );
};

export default Item;