import React from "react";
import { Box, Typography } from "@mui/material";
import styles from "./LeadgenNoData.styles";

const LeadgenNoData = () => {
  return (
    <Box sx={styles.container}>
      <Typography variant="h4" sx={styles.typography}>
        Nothing to show yet. Use the search to find an entity or an object of
        interest
      </Typography>
      <Box sx={styles.imgBg}>
        <img src="images/empty-page.png" alt="empty page" />
      </Box>
    </Box>
  );
};

export default LeadgenNoData;
