import { combineReducers } from "redux";
// import lpsReducer from "./lps";
import settings from './settings';
import searchResult from './search';
// import lps from './lps';

const search = combineReducers({
  // lpsReducer,
  settings,
  searchResult,
  // lps
});

export default search;
