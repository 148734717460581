import { all } from "redux-saga/effects";
import actionEntity from './entityDetails';
import actionNotes from './notes';
import actionStakeholders from './objectStakeholders';
import actionObjects from './objects';


export default function* actionLeadgen() {
  yield all([
    actionEntity(),
    actionNotes(),
    actionStakeholders(),
    actionObjects(),
  ]);
}