export const notificationMessage = {
  LOGIN_SUCCESS: "Welcome back",
  PERMISSIONS_ERROR: "Access is denied",

  LPS_CALCULATION_START: "LPS calculation started!",
  LPS_CALCULATION_FINISH: "LPS calculation completed!",

  BASE_ERROR_MESSAGE: "Ops! Something went wrong!",

  TEST_MESSAGE: "This is test notification message",
};
