import {
  CREATE_NEW_ENTITY_ERROR,
  CREATE_NEW_ENTITY_REQUEST,
  CREATE_NEW_ENTITY_SUCCESS,
  CREATE_NEW_OBJECT_ERROR,
  CREATE_NEW_OBJECT_REQUEST,
  CREATE_NEW_OBJECT_SUCCESS,
} from "../../../common/constants/action-constants";
import AlertService from '../../../common/services/notification.service';

export const createNewEntityRequest = (entity, navigate) => {
  return {
    type: CREATE_NEW_ENTITY_REQUEST,
    entity,
    navigate
  };
};

export const createNewEntitySuccess = (entity) => {
  AlertService.success(`Entity created with id ${entity.id}`);
  return {
    type: CREATE_NEW_ENTITY_SUCCESS,
    entity,
  };
};

export const createNewEntityError = (error) => {
  return {
    type: CREATE_NEW_ENTITY_ERROR,
    error,
  };
};

export const createNewObjectRequest = (object) => {
  return {
    type: CREATE_NEW_OBJECT_REQUEST,
    object,
  };
};

export const createNewObjectSuccess = (object) => {
  AlertService.success(`Object created with id ${object.id}`);
  return {
    type: CREATE_NEW_OBJECT_SUCCESS,
    object,
  };
};

export const createNewObjectError = (error) => {
  return {
    type: CREATE_NEW_OBJECT_ERROR,
    error,
  };
};
