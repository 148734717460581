import {
  GET_OBJECTS_BY_OWNER_ERROR,
  GET_OBJECTS_BY_OWNER_REQUEST,
  GET_OBJECTS_BY_OWNER_SUCCESS,
  GET_OBJECT_BY_ID_REQUEST, GET_OBJECT_BY_ID_SUCCESS, GET_OBJECT_BY_ID_ERROR, SHOW_OBJECT_ADDRESS_ON_MAP
} from '../../../common/constants/action-constants';

export const receiveObjectsByOwnerRequest = (id) => {
  return {
    type: GET_OBJECTS_BY_OWNER_REQUEST,
    id,
  };
};

export const receiveObjectsByOwnerSuccess = (objects) => {
  return {
    type: GET_OBJECTS_BY_OWNER_SUCCESS,
    objects,
  };
};

export const receiveObjectsByOwnerError = (error) => {
  return {
    type: GET_OBJECTS_BY_OWNER_ERROR,
    error,
  };
};

export const receiveObjectByIdRequest = (id) => {
  return {
    type: GET_OBJECT_BY_ID_REQUEST,
    id,
  }
}

export const receiveObjectByIdSuccess = (object) => {
  return {
    type: GET_OBJECT_BY_ID_SUCCESS,
    object,
  }
}

export const receiveObjectByIdError = (error) => {
  return {
    type: GET_OBJECT_BY_ID_ERROR,
    error,
  }
}

export const showAddressOnMap = (address) => {
  return {
    type: SHOW_OBJECT_ADDRESS_ON_MAP,
    address
  }
}
