const styles = {
  checkBoxLabel: {
    color: "#495057",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  mainHolder: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
  },
  checkBoxHolder: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "2em",
  },
  infoHolder: {
    display: "flex",
    flexDirection: "column",
    // marginTop: "4em",
  },
  spinner: {
    display: "flex",
    margin: "0 auto",
  },
  runButton: {
    width: "100%",
    color: "rgba(4, 98, 54, 0.9)",
  },
  resetButton: {
    width: "100%",
    color: "#d01f1f",
    marginBottom: "2em",
  },
  title: {
    fontWeight: "bold",
  },
  list: {
    marginTop: "3em",
    height: "400px",
    overflow: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
  leadLogicHolder: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    marginTop: "2em",
  },
  bestLeadButton: {
    color: "rgba(4, 98, 54, 0.9)",
    width: "100%",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "1em 0",
  },
  currentLead: {
    textAlign: "center",
  },
  calcParamsHolder: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

export default styles;
