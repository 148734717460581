import React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import styles from "./ModalFooter.styles";
import { modalTypes } from "../../../constants/field-constants";

export default function ModalFooter(props: any) {
  return (
    <Box sx={styles.intervalInput}>
      {props.type === modalTypes.CREATE || props.type === modalTypes.SAVE ? (
        <>
          <Button
            variant="contained"
            sx={styles.buttonSave}
            onClick={() => {
              props.onSave();
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            sx={styles.buttonCansel}
            onClick={props.closeModal}
          >
            Cancel
          </Button>
        </>
      ) : props.type === modalTypes.DELETE ? (
        <>
          <Button
            variant="contained"
            sx={styles.buttonDelete}
            onClick={() => {
              props.onSave();
              props.closeModal();
            }}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            sx={styles.buttonCansel}
            onClick={props.closeModal}
          >
            Cancel
          </Button>
        </>
      ) : props.type === modalTypes.UNLINK ? (
        <>
          <Button
            variant="contained"
            sx={styles.buttonDelete}
            onClick={() => {
              props.onSave();
              props.closeModal();
            }}
          >
            Unlink
          </Button>
          <Button
            variant="contained"
            sx={styles.buttonCansel}
            onClick={props.closeModal}
          >
            Cancel
          </Button>
        </>
      ) : (
        <Button
          variant="contained"
          sx={styles.buttonCansel}
          onClick={props.closeModal}
        >
          Close
        </Button>
      )}
    </Box>
  );
}

ModalFooter.propTypes = {
  closeModal: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

ModalFooter.defaultProps = {
  onSave: () => {},
  closeModal: () => {},
};
