const styles = {
  container: {
    height: "480px",
    padding: "5px 15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flex: "1 0 auto",
    overflow: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
  objectsContainer: {
    height: "580px",
    padding: "5px 15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flex: "1 0 auto",
    overflow: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
  mainInfoHolder: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  contentBlockTitle: {
    marginBottom: "10px",
    fontSize: "16px",
    lineHeight: "0.65em",
    fontWeight: "500",
    fontFamily: "typography.fontFamily",
    color: "#495057",
  },
  contentTitleMain: {
    paddingTop: "17px",
    fontSize: "18px",
    lineHeight: "0.65em",
    fontWeight: "500",
    fontFamily: "typography.fontFamily",
    color: "#495057",
  },
  objectContentTitle: {
    fontSize: "18px",
    lineHeight: "0.65em",
    fontWeight: "500",
    fontFamily: "typography.fontFamily",
    color: "#495057",
  },
  contentTitle: {
    paddingTop: "17px",
    fontSize: "16px",
    lineHeight: "0.65em",
    fontWeight: "500",
    fontFamily: "typography.fontFamily",
    color: "#495057",
  },
  contentSubTitle: {
    marginLeft: "15px",
    paddingTop: "17px",
    fontSize: "16px",
    lineHeight: "0.65em",
    fontWeight: "400",
    fontFamily: "typography.fontFamily",
    color: "#495057",
  },
  nameHolder: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "30px",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  infoRow: {
    display: "flex",
  },
  infoHolder: {},
  link: {
    textDecoration: "none",
    color: "rgba(17,75,118,0.8)",
    "&:hover": {
      color: "rgba(17,75,118,1.8)",
    },
  },
  buttonHolder: {
    margin: "15px 0 10px",
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonLinkObjects: {
    width: "130px",
    height: "34px",
    background: "rgba(4, 98, 54, 0.8)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    lineHeight: "1.25em",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "rgba(4, 98, 54, 0.9)",
      borderColor: "#046236",
      boxShadow: "none",
    },
  },
  buttonUnlinkObjects: {
    marginLeft: "20px",
    width: "130px",
    height: "34px",
    background: "#D67575",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    lineHeight: "1.25em",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#d01f1f",
      borderColor: "#e53a3a",
      boxShadow: "none",
    },
  },
  buttonEdit: {
    width: "95px",
    height: "34px",
    background: "rgba(4, 98, 54, 0.8)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    lineHeight: "1.25em",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "rgba(4, 98, 54, 0.9)",
      borderColor: "#046236",
      boxShadow: "none",
    },
  },
  buttonDelete: {
    marginLeft: "10px",
    width: "95px",
    height: "34px",
    background: "#D67575",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1.25em",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#d01f1f",
      borderColor: "#e53a3a",
      boxShadow: "none",
    },
  },
  buttonCansel: {
    marginLeft: "10px",
    width: "95px",
    height: "34px",
    background: "rgba(17,75,118,0.8)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    lineHeight: "1.25em",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "rgba(17,75,118,0.9)",
      borderColor: "#114B76",
      boxShadow: "none",
    },
  },
  buttonEdit2: {
    marginLeft: "10px",
    width: "110px",
    height: "40px",
    background: "rgba(17,75,118,0.8)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    lineHeight: "1.25em",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "rgba(17,75,118,0.7)",
      borderColor: "#114B76",
      boxShadow: "none",
    },
  },
  editorArea: {
    height: "162px",
    maxHeight: "286px",
    maxWidth: "650px",
    overflow: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
  userInfo: {
    margin: "10px 0",
  },
  editButtonGroup: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  stakeholderName: {
    fontSize: "16px",
    lineHeight: "1.20em",
    fontWeight: "500",
    fontFamily: "typography.fontFamily",
    color: "#495057",
  },
  ownersHolder: {
    paddingTop: "17px",
  },
  firstIconHolder: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  secondIconHolder: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    fontSize: "16px",
  },
  relatedInfoItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  colorAgo: {
    color: "#d01f1f",
  },
  colorIn: {
    color: "#495057",
  },
  objectTable: {
    marginTop: "15px",
    maxHeight: 460,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
  imgKadLink: {
    height: 20,
    width: 20,
    marginLeft: 4,
    "&:hover": {
      outline: "4px solid rgba(0,0,0,0.2)",
    },
  },
};

export default styles;
