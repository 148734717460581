import * as React from "react";
import styles from "./Header.styles";
import {
  Box,
  Button,
  ButtonGroup,
  CssBaseline,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { selectUser } from "../../../modules/login/selectors";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import eventEmitter, { types } from "../helpers/eventEmitter";
import LPSMenu from "../lps-menu/LPSMenu";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  lpsMenuOpen?: boolean;
  drawerOpen?: () => void;
  drawerLPSOpen?: () => void;
  drawerWidth?: number;
  userId?: number;
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "lpsMenuOpen",
})<AppBarProps>(({ theme, open, lpsMenuOpen }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(lpsMenuOpen && {
    marginRight: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = ({
  drawerOpen,
  open,
  userId,
  drawerLPSOpen,
  lpsMenuOpen,
}: AppBarProps) => {
  const location = useLocation();

  const getTitle = () => {
    if (location.pathname.includes("leadgen")) {
      const updatedTitle = "Leadgen";
      return updatedTitle;
    }
    if (location.pathname.includes("-")) {
      const updatedTitle = location.pathname.replace("-", " ");

      if (updatedTitle.includes("pdf")) {
        const newTitle = updatedTitle.replace("pdf", "PDF");
        return `${newTitle.charAt(1).toUpperCase()}${newTitle.slice(2)}`;
      }
      if (updatedTitle.includes("lps")) {
        const newTitle = updatedTitle.replace("lps", "LPS");
        return `${newTitle.charAt(1).toUpperCase()}${newTitle.slice(2)}`;
      }
      if (updatedTitle.includes("super")) {
        const newTitle = updatedTitle.replace(" ", "");
        return `${newTitle.charAt(1).toUpperCase()}${newTitle.slice(2)} panel`;
      }
      return `${updatedTitle.charAt(1).toUpperCase()}${updatedTitle.slice(2)}`;
    } else {
      if (location.pathname === "/admin") {
        return `${location.pathname
          .charAt(1)
          .toUpperCase()}${location.pathname.slice(2)} panel`;
      }
      return `${location.pathname
        .charAt(1)
        .toUpperCase()}${location.pathname.slice(2)}`;
    }
  };

  return (
    <Box sx={styles.header}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        lpsMenuOpen={lpsMenuOpen}
        sx={styles.appBar}
      >
        <Toolbar sx={styles.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={drawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={styles.headerTitle}
          >
            {getTitle()}
          </Typography>
          <ButtonGroup variant="outlined" aria-label="text button group">
            <Button
              sx={styles.headerButton}
              onClick={() =>
                eventEmitter.emit(types.openGlobalSearchModal, userId)
              }
            >
              Search
            </Button>
            <Button sx={styles.headerButton} onClick={drawerLPSOpen}>
              LPS
            </Button>
          </ButtonGroup>
          <LPSMenu lpsMenuOpen={lpsMenuOpen} />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

function mapStateToProps(state: any) {
  const { userData, userId } = selectUser(state);
  return { userData, userId };
}

export default connect(mapStateToProps)(Header);
