import {
  ADMIN_GET_USERS_REQUEST,
  ADMIN_GET_USERS_SUCCESS,
  ADMIN_GET_USERS_ERROR,
  ADMIN_GET_USER_LOGS_REQUEST,
  ADMIN_GET_USER_LOGS_SUCCESS,
  ADMIN_GET_USER_LOGS_ERROR,
  ADMIN_DELETE_USER_REQUEST,
  ADMIN_DELETE_USER_ERROR,
  ADMIN_CREATE_USER_REQUEST,
  ADMIN_CREATE_USER_ERROR,
  ADMIN_UPDATE_USER_REQUEST,
  ADMIN_UPDATE_USER_ERROR,
  ADMIN_GET_USER_DETAILS_REQUEST,
  ADMIN_GET_USER_DETAILS_SUCCESS,
  ADMIN_GET_USER_DETAILS_ERROR,
} from "../../../common/constants/action-constants";

export const adminGetUsersRequest = () => {
  return {
    type: ADMIN_GET_USERS_REQUEST,
  };
};

export const adminGetUsersSuccess = (users) => {
  return {
    type: ADMIN_GET_USERS_SUCCESS,
    users: users,
  };
};

export const adminGetUsersError = (error) => {
  console.log("error ", error);
  return {
    type: ADMIN_GET_USERS_ERROR,
    error,
  };
};

export const adminGetUserLogsRequest = (id) => {
  return {
    type: ADMIN_GET_USER_LOGS_REQUEST,
    id,
  };
};

export const adminGetUserLogsSuccess = (userLogs) => {
  return {
    type: ADMIN_GET_USER_LOGS_SUCCESS,
    logs: userLogs,
  };
};

export const adminGetUserLogsError = (error) => {
  console.log("error ", error);
  return {
    type: ADMIN_GET_USER_LOGS_ERROR,
    error,
  };
};

export const adminCreateUserRequest = (user) => {
  return {
    type: ADMIN_CREATE_USER_REQUEST,
    user,
  };
};

export const adminCreateUserError = (error) => {
  return {
    type: ADMIN_CREATE_USER_ERROR,
    error,
  };
};

export const adminUpdateUserRequest = (id, user) => {
  return {
    type: ADMIN_UPDATE_USER_REQUEST,
    id,
    user,
  };
};

export const adminUpdateUserError = (error) => {
  return {
    type: ADMIN_UPDATE_USER_ERROR,
    error,
  };
};

export const adminDeleteUserRequest = (id) => {
  return {
    type: ADMIN_DELETE_USER_REQUEST,
    id,
  };
};

export const adminDeleteUserError = (error) => {
  return {
    type: ADMIN_DELETE_USER_ERROR,
    error,
  };
};

export const adminGetUserDetailsRequest = (id) => {
  return {
    type: ADMIN_GET_USER_DETAILS_REQUEST,
    id,
  };
};
export const adminGetUserDetailsSuccess = (user) => {
  return {
    type: ADMIN_GET_USER_DETAILS_SUCCESS,
    user,
  };
};
export const adminGetUserDetailsError = (error) => {
  return {
    type: ADMIN_GET_USER_DETAILS_ERROR,
    error,
  };
};

//
// export const adminCreateUserRequest = () => {

// export const adminCreateUserRequest = (user) => {
//   return {
//     type: ADMIN_GET_USERS_REQUEST,
//   }
// }
// export const adminCreateUserSuccess = (users) => {
//   return {
//     type: ADMIN_GET_USERS_SUCCESS,
//     users: users
//   }
// }
// export const adminCreateUserError = (error) => {
//   console.log("error ", error);
//   return {
//     type: ADMIN_GET_USERS_ERROR,
//     error
//   }
// }
