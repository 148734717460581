import { createSelector } from "reselect";

const leadsState = (state) => {
  if (state && state.leads) {
    return state.leads;
  }
  return null;
};

const leadsSelector = createSelector(
  [leadsState],
  (state) => state //return leads
);

const setLeadIdSelector = createSelector(
  [leadsState],
  (state) => state.setLeadId
);

export const selectLeads = createSelector([leadsSelector, setLeadIdSelector], (leads, setLeadId) => {
  const assignedLeadsList = leads ? leads.assigned.assigned : false;
  const scheduledLeads = leads ? leads.scheduled.scheduled : false;
  const currentLeadId = setLeadId ? setLeadId : null;
  const leadsList = leads ? leads.receive : [];
  // console.log('SELECTORS assignedLeadsList ', assignedLeadsList);
  //
  return {
    leads,
    leadsList,
    assignedLeadsList,
    scheduledLeads,
    currentLeadId,
    setLeadId,
  }
})
