import { deepPurple } from "@mui/material/colors";

const styles = {
  defaultAvatar: {
    background: deepPurple[500],
  },
  cardAvatar: {
    width: "80px",
    height: "80px",
    background: "#E1E1EE",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "36px",
    color: "#015A9C",
    border: "5px solid white",
  },
  avatarAdminPanel: {
    width: "120px",
    height: "120px",
    background: "rgba(180, 206, 229, 0.44)",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "48px",
    color: " #015A9C",
    marginRight: "30px",
  },
  leadgenEntityRatingAvatar: {
    background: "#E1E1EE",
    color: "#015A9C",
    width: "80px",
    height: "80px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
  }
};

export default styles;
