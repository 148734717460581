import { combineReducers } from 'redux';
import {
  DELETE_TAG_ERROR,
  RECEIVE_LABELS_LIST_ERROR,
  RECEIVE_LABELS_LIST_SUCCESS,
  RECEIVE_TAGS_LIST_ERROR,
  RECEIVE_TAGS_LIST_SUCCESS,
  UPDATE_TAG_ERROR,
  UPDATE_LABEL_ERROR,
  DELETE_LABEL_ERROR,
  CREATE_NEW_LABEL_ERROR,
  CREATE_NEW_TAG_ERROR,

} from '../../../common/constants/action-constants';

const receiveLabelsList = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_LABELS_LIST_SUCCESS:
      return action.labelsList;
    default:
      return state;
  }
};

const receiveTagsList = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_TAGS_LIST_SUCCESS:
      return action.tagsList;
    default:
      return state;
  }
};

const error = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_LABELS_LIST_ERROR:
    case RECEIVE_TAGS_LIST_ERROR:
    case UPDATE_TAG_ERROR:
    case DELETE_TAG_ERROR:
    case UPDATE_LABEL_ERROR:
    case DELETE_LABEL_ERROR:
    case  CREATE_NEW_LABEL_ERROR:
    case CREATE_NEW_TAG_ERROR:
      return action.error;
    default:
      return state;
  }
};

const superAdmin = combineReducers({
  labelsList: receiveLabelsList,
  tagsList: receiveTagsList,
  error
});

export default superAdmin;